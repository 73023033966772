// import React from 'react';

// const IconLoader = () => (
//   <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
//     <title>Loader Logo</title>
//     <g>
//       <g id="B" transform="translate(11.000000, 5.000000)">
//         <path
//           d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
//           fill="currentColor"
//         />
//       </g>
//       <path
//         stroke="currentColor"
//         strokeWidth="5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M 50, 5
//                   L 11, 27
//                   L 11, 72
//                   L 50, 95
//                   L 89, 73
//                   L 89, 28 z"
//       />
//     </g>
//   </svg>
// );

// export default IconLoader;

import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="437.000000pt"
    height="257.000000pt"
    viewBox="0 0 437.000000 257.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
    <g transform="translate(0.000000,257.000000) scale(0.100000,-0.100000)">
      <path
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4299 2556 c-2 -2 -58 -9 -124 -15 -168 -16 -520 -94 -694 -152 -166
-56 -388 -162 -518 -248 -179 -117 -401 -328 -538 -510 -50 -66 -156 -235
-264 -421 -6 -11 -20 9 -55 79 -32 63 -51 90 -58 83 -5 -5 -12 -46 -15 -91 -5
-75 -4 -86 25 -149 17 -37 45 -102 62 -145 18 -42 35 -77 40 -77 4 0 36 65 70
145 82 190 158 325 271 481 160 220 374 417 594 546 206 121 473 225 671 262
65 12 225 30 278 31 20 0 26 -61 15 -140 -13 -98 -86 -300 -140 -389 -21 -36
-39 -71 -39 -78 0 -19 62 -78 82 -78 16 0 124 137 185 235 53 85 129 240 161
329 34 92 69 265 59 290 -6 14 -56 23 -68 12z"
      />
      <path
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40 2531 c-14 -4 -26 -14 -28 -24 -11 -58 88 -393 170 -577 59 -132
187 -309 317 -439 172 -172 519 -370 721 -410 l55 -11 -57 -61 c-62 -66 -113
-156 -149 -264 -21 -62 -24 -87 -24 -230 1 -189 12 -241 86 -392 39 -81 54
-103 70 -103 34 0 250 103 340 162 122 79 236 197 286 295 70 136 78 169 78
338 0 168 -15 235 -82 370 -103 204 -314 400 -568 525 -274 135 -502 171 -698
110 -76 -24 -81 -34 -42 -93 29 -42 32 -43 81 -22 101 42 229 38 389 -12 119
-38 212 -85 359 -183 204 -136 334 -287 407 -473 29 -73 33 -95 37 -205 4
-108 1 -132 -19 -194 -29 -92 -81 -168 -173 -256 -74 -70 -244 -180 -314 -203
-31 -10 -37 -9 -49 7 -47 64 -79 238 -70 386 4 82 8 96 62 204 74 152 125 205
258 268 103 49 137 81 137 129 l0 30 -47 -8 c-27 -4 -70 -11 -98 -15 -154 -24
-473 85 -660 225 -80 60 -226 205 -287 285 -117 153 -194 370 -206 583 l-7
117 32 0 c105 0 384 -85 558 -169 365 -177 602 -372 921 -758 37 -46 74 -83
80 -83 7 0 16 20 20 45 3 25 8 44 11 42 2 -3 -3 -58 -13 -123 -25 -180 -15
-443 24 -629 50 -236 152 -512 186 -503 19 6 102 186 140 303 54 165 98 382
116 569 11 111 3 239 -14 233 -6 -2 -27 -35 -48 -73 -31 -58 -38 -85 -48 -170
-12 -116 -46 -249 -91 -365 l-32 -80 -24 58 c-41 99 -101 325 -113 425 -8 70
-8 129 0 224 13 153 26 219 80 409 38 135 40 146 26 178 -17 42 -30 51 -45 34
-18 -22 -99 -276 -120 -377 -11 -52 -20 -85 -20 -73 -1 27 -177 228 -317 363
-283 272 -656 488 -1020 590 -178 50 -495 90 -564 71z"
      />
      <path
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1575 2528 c-28 -16 -35 -28 -35 -65 0 -38 29 -73 61 -73 32 0 59 19
59 40 0 11 5 20 10 20 33 0 169 -93 224 -153 121 -134 230 -315 280 -467 28
-88 76 -296 76 -334 0 -42 28 -45 61 -5 52 62 53 76 24 185 -29 111 -85 257
-123 324 -19 32 -21 41 -9 31 10 -8 19 -12 21 -10 2 2 31 45 64 94 86 127 180
232 254 282 l63 42 18 -24 c39 -52 112 -19 105 47 -3 29 -8 35 -45 47 -57 20
-102 5 -200 -68 -137 -102 -319 -312 -308 -356 5 -17 3 -16 -12 5 -125 174
-249 309 -343 373 -93 65 -197 92 -245 65z"
      />
      <path
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3405 1859 c-213 -31 -511 -191 -710 -381 -108 -103 -164 -182 -235
-330 -66 -139 -81 -199 -87 -353 -5 -116 -3 -137 17 -195 75 -221 172 -346
365 -467 132 -83 392 -158 422 -121 6 7 20 67 32 133 36 207 23 455 -33 617
-31 89 -109 219 -158 263 -39 36 -39 45 0 45 42 0 260 78 367 131 55 27 140
78 189 113 116 84 276 217 276 231 0 17 -60 85 -76 85 -8 0 -61 -40 -119 -89
-167 -144 -339 -248 -523 -318 -136 -51 -312 -68 -420 -39 -57 14 -67 9 -58
-29 11 -42 55 -83 146 -135 57 -33 106 -70 135 -103 59 -67 141 -232 160 -322
18 -84 19 -246 4 -366 l-11 -88 -80 29 c-391 143 -580 448 -489 790 31 118 70
195 157 310 149 198 348 341 584 421 83 28 112 32 230 36 121 5 141 3 193 -17
78 -30 149 -88 178 -147 19 -38 24 -64 24 -128 0 -71 -4 -89 -33 -147 -43 -88
-130 -168 -249 -231 -89 -46 -135 -59 -259 -71 l-60 -7 4 34 c7 65 -64 92
-113 42 -54 -54 -16 -124 79 -146 33 -7 99 -10 177 -6 104 4 138 9 209 34 119
42 141 55 218 128 118 111 157 210 150 389 -5 137 -25 185 -111 269 -124 120
-287 165 -492 136z"
      />
      <path
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M312 1653 c-19 -7 -58 -90 -71 -151 -30 -143 17 -296 133 -428 43
-50 182 -123 276 -145 113 -27 307 -9 372 34 67 46 56 127 -17 127 -38 0 -52
-14 -57 -55 -3 -29 -5 -30 -62 -33 -72 -4 -216 35 -291 78 -166 95 -278 325
-216 444 l20 39 -30 43 c-33 49 -39 53 -57 47z"
      />
    </g>
  </svg>
);

export default IconLoader;